.header{
    margin-bottom: 35px;
}

.footer{
    height: 25px;
    margin-top: 0px;
    justify-content: center;
    display: flex;
}

.body{
    padding-bottom: 45px;
}

.pointer {
    cursor: pointer;
}

.impressum {
    display: block;
    width: 100px;
}

img {
    max-width: 100%;
    max-height: 100%;
    height: inherit;
    object-fit: contain;
}

body > #root > div {
    height: 100vh;
    margin-left: 20%;
    margin-right: 20%;
}

.fartinglady {
    float: right;
    width: 100%;
    display: flex;
    height: 100%;
}

.icons {
    width: auto;
    margin-left: auto;
    margin-right: 0;
    align-items: center;
    display: grid;
}

.icons > img {
    width: 35px;
    display: block;
}

.lady {
}


.fartinaction {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.about{
    position: relative;
    left: 75px;
    width: 65px;
    bottom: 10px;
}


.language {
    position: relative;
    left: 150px;
    width: 50px;
    top: 10px;
}

.insta {
    position: relative;
    left: 135px;
    width: 45px;
    bottom: 25px;
}

.mail {
    position: relative;
    left: 80px;
    width: 45px;
    bottom: 10px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-40px, -10px, 0);
    }

    20%, 80% {
        transform: translate3d(40px, 10px, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-40px, -10px, 0);
    }

    40%, 60% {
        transform: translate3d(40px, 10px, 0);
    }
}

.swiper-button-prev, .swiper-button-next {
    color: dimgray;
}


.swiper {
    width: 100%;
    height: 100%;
}

.slider-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

@media only screen and (max-width: 1080px) {
    .MuiImageList-root {
        column-count: 1 !important;
    }
}

iframe{
    border-width: 0px;
    height: 8000px;
}


/*@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

.fartinglady-child:hover {
    animation: shake 0.82s cubic-bezier(.1,.07,.1,.1) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }*/